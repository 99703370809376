import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Icon, IconButton, Link, Stack, Typography, alpha } from '@mui/material';
import { ArrowRightAlt, Loop, Mail, MarkEmailRead, MarkEmailUnread, Repeat } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

import { LOGIN, RESEND, SOCIAL_MEDIA, VERIFY } from '../utils/helper/link-config';

import nsc_orange from '../assets/orange-nsc.png';

export default function VerifyInfo(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    document.title = "Verify | nanoStream Cloud Dashboard";

    const handleClickedSocialMediaLink = (link) => () => window.open(link);

    return (
        <Grid container p={2}
            alignItems={"center"} justifyContent={"center"}
            sx={{
                minHeight: '100vh', height: '100%', maxHeight: '100%',
                bgcolor: theme.palette.secondary.light,
                backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`
            }}
        >
            <Grid item xs={12} md={6}>
                <Box sx={{ bgcolor: theme.palette.common.white, p: 2, borderRadius: 2, textAlign: 'center' }}>
                    <Box
                        component="img" src={nsc_orange} mb={2}
                        sx={{ height: { xs: 40, md: 65 } }}
                    />
                    <Icon sx={{ display: 'block', margin: '0 auto' }}>
                        <MarkEmailUnread color="primary" />
                    </Icon>
                    <Typography variant="h4" color="secondary">
                        Verify Your Email Address
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        A verification email has been sent to your email. <br />
                        Please check your email and click the link provided in the email to complete your account registration.
                    </Typography>
                    <Button
                        endIcon={<Loop />}
                        sx={{ mt: 1 }}
                        variant="contained"
                        onClick={() => navigate(`${VERIFY}${RESEND}`)}
                    >
                        Resend Email
                    </Button>
                    <Typography variant="body1" color="primary" sx={{ fontWeight: 700, mt: 2 }}>
                        Get in touch with us!
                    </Typography>
                    <Stack direction="row" useFlexGap spacing={1} justifyContent={"center"} flexWrap={"wrap"}>
                        {
                            SOCIAL_MEDIA.map((s, i) => (
                                <IconButton key={i}
                                    color="primary" size="medium"
                                    onClick={handleClickedSocialMediaLink(s.link)}
                                >
                                    {s.icon}
                                </IconButton>
                            ))
                        }
                    </Stack>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Already have an account? <Link href={LOGIN}>Login</Link>.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
