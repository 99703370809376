import { Facebook, GitHub, Instagram, Language, LinkedIn, Twitter, YouTube } from "@mui/icons-material";

// FRONTEND REDIRECTS
export const AUTH = '/auth';
export const INVITE_TOKEN = '/invite'
export const LOGIN = '/auth';
export const SIGNUP = '/signup';
export const WELCOME = '/welcome';
export const VERIFY = '/verify';
export const RESEND = '/resend';
export const EMAIL = '/email';
export const SET_PASSWORD = '/setpassword';
export const STREAMS = '/stream';
export const STREAM = '/stream';
export const DASHBOARD = '/';
export const CREATE_STREAM = '/stream/create';
export const PLAYBACK_TOKEN = '/secure/token';
export const NEW_STREAM = '/stream/new';
export const ORGANISATION = '/organisation';
export const LOGOUT = '/logout';
export const USER = '/organisation';
export const SUPPORT = '/support';
export const ABOUT = '/about';
export const PLAYOUT = '/playout';
export const METRICS = '/metrics';
export const WEBCASTER = '/webcaster';
export const ALERTS = '/alerts';
export const WORLDMAP = '/worldmap';

export const THUMBNAILS = 'https://bintu-vod.nanocosmos.de/vod/thumbnails'

export const BINTU_PRODUCTION_BASE = 'https://dashboard.nanostream.cloud';
export const BINTU_DEV_BASE = 'https://dashboard-dev.nanostream.cloud';


// CHANGE HERE FOR DEPLOYMENT
export const BASE = window.DASHBOARD_BASE || BINTU_DEV_BASE;

// API URLs  
export const BINTU_PROD = "https://bintu.nanocosmos.de";
export const BINTU_STAGING = "https://bintu-staging.nanocosmos.de";
export const BINTU_DEV_API = "https://bintu-dev.nanocosmos.de";
export const BINTU_CANARY = "https://bintu-canary-eu.bintu-glb.nanostream.cloud";
export const BINTU_API = window.BINTU_API || BINTU_DEV_API;

export const CTS_JWT_PROD = 'https://token.nanostream.cloud/api/v1/splay';
export const CTS_JWT_STAGING = 'https://token-staging.nanostream.cloud/api/v1/splay';

export const CTS_PROD = 'https://token.nanostream.cloud/bintu';
export const CTS_JWT_DEV = "https://token-dev.nanostream.cloud/api/v1/splay";
export const NTS_PROD_API = "https://bintu-nts.nanocosmos.de/secure";

export const TOKEN_API = window.TOKEN_API || CTS_JWT_DEV;

export const METRICS_PROD = "https://metrics.nanocosmos.de/api";
export const METRICS_DEV_API = "https://metrics-dev.nanocosmos.de/api";

export const METRICS_API = window.METRICS_API || METRICS_DEV_API;

export const REPLAYER_WEBDEMO_DEV = 'https://ffmpeg-service.pages.nanocosmos.de/live-replay/main/replayer'
export const CLIPPING_WEBDEMO_DEV = 'https://ffmpeg-service.pages.nanocosmos.de/live-replay/main/clipping'

export const REPLAYER_WEBDEMO_PROD = 'https://web-demos.pages.nanocosmos.de/live-replay/main/replayer'
export const CLIPPING_WEBDEMO_PROD = 'https://web-demos.pages.nanocosmos.de/live-replay/main/clipping'

export const CLIPPING_WEBDEMO = window.CLIPPING_WEBDEMO || CLIPPING_WEBDEMO_DEV;
export const REPLAYER_WEBDEMO = window.REPLAYER_WEBDEMO || REPLAYER_WEBDEMO_DEV;

// SOCIAL MEDIA
export const GITHUB = 'https://github.com/nanostream';
export const FACEBOOK = 'https://www.facebook.com/nanocosmos.net/';
export const YOUTUBE = 'https://www.youtube.com/channel/UCsWDxJax9j3GrMDufHBwgKA';
export const INSTAGRAM = 'https://www.instagram.com/nanovideo/';
export const LINKEDIN = 'https://www.linkedin.com/company/nanocosmos-gmbh/';
export const WEBSITE = 'https://info.nanocosmos.de/';
export const TWITTER = 'https://twitter.com/nanovideo';
export const BLOG = 'https://www.nanocosmos.de/blog/';

export const SOCIAL_MEDIA = [
  { icon: (<LinkedIn fontSize="inherit" />), link: LINKEDIN },
  { icon: (<Language fontSize="inherit" />), link: WEBSITE },
  { icon: (<GitHub fontSize="inherit" />), link: GITHUB },
  { icon: (<YouTube fontSize="inherit" />), link: YOUTUBE },
  { icon: (<Twitter fontSize="inherit" />), link: TWITTER },
  { icon: (<Facebook fontSize="inherit" />), link: FACEBOOK },
  { icon: (<Instagram fontSize="inherit" />), link: INSTAGRAM },
];

// DASHBOARD
export const DASHBOARD_V3_RELEASE = 'https://www.nanocosmos.de/blog/2022/09/nanostream-cloud-2022-and-dashboard-v3-release-notes/';
export const DASHBOARD_OVERVIEW = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Overview';
export const DASHBOARD_ABR_TRANSCODE = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_ABR_Transcoding';
export const DASHBOARD_THUMBNAILS = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Thumbnail';
export const DASHBOARD_TIMECODE = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming#add-timecode-injection';
export const DASHBOARD_OPCODES = "https://docs.nanocosmos.de/docs/cloud/live_processing";
export const DASHBOARD_START_STREAMING = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming';

export const DASHBOARD_CREATE_STREAM_OPCODES = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming#add-live-processing';
export const DASHBOARD_CREATE_STREAM_TRANSCODES = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming#add-transcoding';
export const DASHBOARD_CREATE_STREAM_TIMECODE = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Start_Streaming#add-timecode-injection';


export const OPCODES = 'https://docs.nanocosmos.de/docs/cloud/live_processing';
export const OPCODES_THUMBS = 'https://docs.nanocosmos.de/docs/cloud/live_processing#thumbnail';
export const OPCODES_MOTIONCLIP = 'https://docs.nanocosmos.de/docs/cloud/live_processing#motion-clip';
export const OPCODES_REPLAY = 'https://docs.nanocosmos.de/docs/cloud/live_processing#replay';
export const OPCODES_RECORDING = 'https://docs.nanocosmos.de/docs/cloud/live_processing#recording';

export const USER_ROLES_BINTU = 'https://docs.nanocosmos.de/docs/user_roles#user-roles-in-nanostream-cloud';


// H5LIVE
export const NANOPLAYER_RELEASE_SRC = 'https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js'

export const H5LIVE_EMBED_PLAYER_PROD = 'https://demo.nanocosmos.de/nanoplayer/embed/1.3.3/nanoplayer.html?';
export const H5LIVE_EMBED_PLAYER_DEV = 'https://demo1.nanocosmos.de/nanoplayer/embed/1.3.0/nanoplayer.html?group.apiurl=https://bintu-dev-k8s.nanocosmos.de&';
export const H5LIVE_EMBED_PLAYER = H5LIVE_EMBED_PLAYER_PROD;

export const H5LIVE_DOCS = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_introduction/';
export const DOCS_LATENCY_CONTROL = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_latency_control_modes';
export const DOCS_STREAM_SWTICH = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_stream_switching';
export const H5LIVE_DOCS_ERROR_CODES = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api#nanoplayererrorcode--codenumbercode';
export const H5LIVE_LATEST_RELEASE = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_release_latest';
export const H5LIVE_SECURITY = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security';
export const H5LIVE_GETTING_STARTED = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_getting_started';
export const H5LIVE_API = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api';
export const H5LIVE_NEWS = 'https://www.nanocosmos.de/blog/category/product-updates/h5live/';
export const H5LIVE_ABR = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_stream_switching';
export const H5LIVE_VOD = 'https://docs.nanocosmos.de/docs/cloud/live-recording-vod';

// TOKEN
export const TOKEN_SECURITY_IP = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#c-with-a-client-ip';
export const TOKEN_SECURITY_REFERER = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#d-with-a-custom-tag-and-a-referer';
export const TOKEN_SECURITY_DOCS = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security';
export const EDIT_BINTU_TOKEN_DOCS = 'https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_User_Roles#1-edit-a-users-role';

// WEBCASTER
export const WEBCASTER_INTRO = 'https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_introduction/';
export const WEBCASTER_GETTING_STARTED = 'https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_getting_started';
export const WEBCASTER_API = 'https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_api';
export const WEBCASTER_NEWS = 'https://www.nanocosmos.de/blog/category/product-updates/nanostream-webcaster/';

// METRICS
export const METRICS_DASHBOARD = 'https://metrics.nanocosmos.de'
export const METRICS_LOGIN = 'https://metrics.nanocosmos.de/#/login'
export const METRICS_DOCS = 'https://docs.nanocosmos.de/docs/cloud/analytics/';
export const METRICS_API_DOC = 'https://metrics.nanocosmos.de/api/doc/';
export const METRICS_NEWS = 'https://www.nanocosmos.de/blog/category/product-updates/nanostream-analytics/';

// HELPER LINKS
export const DOCS = 'https://docs.nanocosmos.de/';
export const READ_ME = 'https://docs.nanocosmos.de/nanostream-cloud-dashboard-readme.html';
export const FEEDBACK = 'https://www.nanocosmos.de/feedback';
export const BINTU = 'https://bintu.nanocosmos.de/';
export const NANO_SUPPORT = 'https://www.nanocosmos.de/support';
export const CONTACT = 'https://www.nanocosmos.de/contact';
export const IMPRINT = 'https://www.nanocosmos.de/terms';
export const BUY = 'https://www.nanocosmos.de/buy';
export const BINTU_DASHBOARD_V2 = 'https://bintu-cloud-frontend.nanocosmos.de/';

// NSC
export const NSC_INTRO = 'https://docs.nanocosmos.de/docs/cloud/cloud_introduction';
export const NSC_GETTING_STARTED = 'https://docs.nanocosmos.de/docs/cloud/cloud_getting_started';
export const NSC_API = 'https://bintu.nanocosmos.de/doc/';
export const NSC_NEWS = 'https://www.nanocosmos.de/blog/category/product-updates/';
export const NSC_HOW_TO = 'https://docs.nanocosmos.de/docs/cloud-frontend/cloud-readme';

// NANOMEET
export const NM_INTRO = 'https://docs.nanocosmos.de/docs/nanomeet/introduction';
export const NM_GETTING_STARTED = 'https://docs.nanocosmos.de/docs/nanomeet/getting-started';
export const NM_API = 'https://docs.nanocosmos.de/docs/nanomeet/api';
export const NM_SAMPLES = 'https://docs.nanocosmos.de/docs/nanomeet/samples';
export const NM_NEWS = 'https://www.nanocosmos.de/blog/tag/nanomeet/';

// GETTING STARTED LINKS
export const GETTING_STARTED_CREATE_STREAM = 'https://youtu.be/om3cOpV0WKo';

// DOCS_ALERTS
export const ALERTS_DOCS = 'https://docs.nanocosmos.de/docs/cloud/alerting';
export const ALERTS_GENERAL = 'https://docs.nanocosmos.de/docs/cloud/alerting#introduction';
export const ALERTS_HOW_TO_USE = 'https://docs.nanocosmos.de/docs/cloud/alerting#how-to-use';
export const ALERTS_CODES = 'https://docs.nanocosmos.de/docs/cloud/alerting#alert-codes';
export const ADVICE_CODES = 'https://docs.nanocosmos.de/docs/cloud/alerting#advices';