import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, Grid, Icon, IconButton, InputAdornment, Link, Stack, TextField, Tooltip, Typography, alpha } from '@mui/material';
import { ArrowRightAlt, Loop, Mail, MarkEmailRead, MarkEmailUnread, Repeat, Send } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

import { LOGIN, RESEND, SOCIAL_MEDIA, VERIFY } from '../utils/helper/link-config';

import nsc_orange from '../assets/orange-nsc.png';
import { resendVerifyEmail } from '../utils/bintu/api-requests';

export default function VerifyResendEmail(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState("");

    document.title = "Resend Email | nanoStream Cloud Dashboard";

    const handleClickedSocialMediaLink = (link) => () => window.open(link);

    const handleSendNewMail = () => {
        resendVerifyEmail({ email })
            .then((response) => {
                navigate(VERIFY);
            }).catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        const emailFromParams = searchParams.get("email");
        if (emailFromParams) setEmail(emailFromParams);
    }, [searchParams]);

    return (
        <Grid container p={2}
            alignItems={"center"} justifyContent={"center"}
            sx={{
                minHeight: '100vh', height: '100%', maxHeight: '100%',
                bgcolor: theme.palette.secondary.light,
                backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`
            }}
        >
            <Grid item xs={12} md={6}>
                <Box sx={{ bgcolor: theme.palette.common.white, p: 2, borderRadius: 2, textAlign: 'center' }}>
                    <Box
                        component="img" src={nsc_orange} mb={2}
                        sx={{ height: { xs: 40, md: 65 } }}
                    />
                    <Icon sx={{ display: 'block', margin: '0 auto' }}>
                        <MarkEmailUnread color="primary" />
                    </Icon>
                    <Typography variant="h4" color="secondary">
                        Resend Verification Email
                    </Typography>
                    <TextField
                        sx={{ my: 2, width: 300, maxWidth: '100%' }}
                        value={email} label={"E-Mail"}
                        onChange={(e) => { setEmail(e.target.value); }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip arrow title="Send">
                                        <IconButton size="small" onClick={handleSendNewMail}  >
                                            <Send />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                        }}
                    />
                    <Box />
                    <Typography variant="body1" color="primary" sx={{ fontWeight: 700, mt: 2 }}>
                        Get in touch with us!
                    </Typography>
                    <Stack direction="row" useFlexGap spacing={1} justifyContent={"center"} flexWrap={"wrap"}>
                        {
                            SOCIAL_MEDIA.map((s, i) => (
                                <IconButton key={i}
                                    color="primary" size="medium"
                                    onClick={handleClickedSocialMediaLink(s.link)}
                                >
                                    {s.icon}
                                </IconButton>
                            ))
                        }
                    </Stack>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Already have an account? <Link href={LOGIN}>Login</Link>.
                    </Typography>
                </Box>
            </Grid>
        </Grid >
    )
}
