import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Accordion, AccordionDetails, AccordionSummary, Alert, Badge, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, Stack, Table, TableBody, TableContainer, TableRow, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ACCESS_LEVEL_NANO } from '../../utils/helper/nanostream-cloud';
import { ArrowRight, ArrowRightAlt, Check, CheckCircle, CheckCircleOutline, CheckRounded, ExpandMore, InfoRounded } from '@mui/icons-material';
import ContentTable from '../global/ContentTable';
import moment from 'moment';
import { putPermissions } from '../../utils/bintu/api-requests';
import { EDIT_BINTU_TOKEN_DOCS } from '../../utils/helper/link-config';
import { GET_USER_ROLE_OBJ } from '../../utils/helper/methods';

export default function EditUser(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { open, close, user, permissions, refresh } = props;
    const [selectedPermission, setSelectedPermission] = useState(user.permission);

    const handleSelectPermission = (newPermission) => () => {
        setSelectedPermission(newPermission);
    }

    const handleSubmit = () => {

        let data = {
            UserId: user.id,
            permissionProfile: selectedPermission.name
        }

        putPermissions(data)
            .then((response) => {
                if (response.success) {
                    refresh();
                    close();
                }
            }).catch((error) => {
                if (!error.success) {
                    console.log("Error updating User Permission", error);
                }
            })
    }

    useEffect(() => {
        if (permissions.length > 0) {
            const permissionObj = GET_USER_ROLE_OBJ(user?.permission);
            setSelectedPermission(permissionObj);
        }
    }, [user, permissions])

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>
                <Stack direction="row" alignItems={"center"} spacing={.2}>
                    <Typography variant="h5" color="primary">
                        Edit user profile settings
                    </Typography>
                    {/* <IconButton size="small">
                        <InfoRounded fontSize="inherit" sx={{ color: alpha(theme.palette.text.secondary, 0.2) }} />
                    </IconButton> */}
                </Stack>
                <Typography variant="body1" color="textSecondary">
                    Manage the permission level of the user account.
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1.5} direction="column" justifyContent={"center"}>
                            {
                                permissions.map((p, i) => {
                                    const permissionObj = GET_USER_ROLE_OBJ(p.name);
                                    if (!permissionObj) return;
                                    let isSelected = selectedPermission?.id === p.id;
                                    let opacity = isSelected ? 1.0 : 0.5;

                                    return (
                                        <Badge
                                            badgeContent={<Check fontSize="10px" />} overlap="rectangular"
                                            color={permissionObj?.color} invisible={!isSelected}
                                        >
                                            <Box key={p.id} sx={{
                                                width: '100%', p: 2, borderRadius: 2, border: 2,
                                                borderColor: alpha(theme.palette[permissionObj?.color].main, opacity)
                                            }}>
                                                <Stack direction="row" spacing={.5} alignItems={"center"} flexWrap={"wrap"}>
                                                    <Typography variant="h5" sx={{ color: alpha(theme.palette[permissionObj.color].main, opacity) }}>
                                                        {p.name}
                                                    </Typography>
                                                    <Chip
                                                        disabled={!isSelected}
                                                        size="small" variant="outlined"
                                                        icon={permissionObj.icon}
                                                        color={permissionObj.color}
                                                        label={permissionObj.title}
                                                    />
                                                </Stack>
                                                <Typography variant="body2" color={isSelected ? "textSecondary" : "divider"} gutterBottom>
                                                    {p.description}
                                                </Typography>
                                                <Stack direction="row" spacing={.5} flexWrap={"wrap"}>
                                                    {
                                                        p.audiences.map((a, i) => (
                                                            <Chip
                                                                disabled={!isSelected}
                                                                key={`${p.id}-${i}`} size="small"
                                                                sx={{ textTransform: 'uppercase', mb: .4 }}
                                                                color={permissionObj.color}
                                                                icon={<CheckCircle />}
                                                                label={a}
                                                            />
                                                        ))
                                                    }
                                                </Stack>
                                                <Accordion elevation={0} sx={{ border: 1, borderColor: theme.palette.divider, mt: 2 }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                    >
                                                        <Typography variant="body2" sx={{ color: alpha(theme.palette[permissionObj.color].main, opacity), fontWeight: 700 }}>
                                                            Permissions
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {Object.entries(p.bintu_permission).map(([key, value]) => (
                                                            <Box key={key} sx={{ mb: 2 }}>
                                                                <Typography variant="body2" sx={{ fontWeight: 700, textTransform: 'capitalize', color: alpha(theme.palette[permissionObj.color].main, opacity) }}>
                                                                    {key}
                                                                </Typography>
                                                                <Typography>
                                                                    {Object.entries(value).map(([subKey, subValue]) => (
                                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                                            <CheckCircle sx={{ fontSize: 16 }} color={isSelected ? permissionObj.color : "disabled"} />
                                                                            <Typography variant="subtitle2" ke={subKey} color={isSelected ? "inherit" : "textSecondary"}>
                                                                                {subKey}
                                                                            </Typography>
                                                                        </Stack>
                                                                    ))}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Stack direction="row" spacing={1} mt={1}>
                                                    <Button
                                                        variant="contained" size="small"
                                                        disabled={isSelected}
                                                        startIcon={isSelected && <CheckCircleOutline />}
                                                        onClick={handleSelectPermission(p)}
                                                    >
                                                        {isSelected ? "Selected" : "Select"}
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Badge>

                                    )
                                })
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
                            Updated User
                        </Typography>
                        <ContentTable
                            data={[
                                { label: "Email", value: user.email, copy: true },
                                {
                                    label: "Permission", custom:
                                        <Stack direction="row" spacing={.5}>
                                            <Chip
                                                size="small" variant="outlined"
                                                icon={GET_USER_ROLE_OBJ(user.permission)?.icon}
                                                color={GET_USER_ROLE_OBJ(user.permission)?.color}
                                                label={GET_USER_ROLE_OBJ(user.permission)?.title}
                                            />
                                            <ArrowRightAlt />
                                            <Chip
                                                size="small" variant="outlined"
                                                icon={GET_USER_ROLE_OBJ(selectedPermission?.name)?.icon}
                                                color={GET_USER_ROLE_OBJ(selectedPermission?.name)?.color}
                                                label={GET_USER_ROLE_OBJ(selectedPermission?.name)?.title}
                                            />
                                        </Stack>
                                },
                                { label: "Created at", date: moment(user.created_at).utc().format('MM/DD/YYYY hh:mm a (UTC)') },
                                { label: "User Id", value: user.id, copy: true },
                                { label: "Token Key", value: user.tokenKey, copy: true },
                            ]}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                {
                    selectedPermission.name &&
                    <Alert severity='warning' sx={{mb: 1}}>
                        You are about to switch the user role for <b>{user.email}</b> from    <Chip
                            size="small" variant="outlined"
                            icon={GET_USER_ROLE_OBJ(user.permission)?.icon}
                            color={GET_USER_ROLE_OBJ(user.permission)?.color}
                            label={GET_USER_ROLE_OBJ(user.permission)?.title}
                        /> to  <Chip
                            size="small" variant="outlined"
                            icon={GET_USER_ROLE_OBJ(selectedPermission?.name)?.icon}
                            color={GET_USER_ROLE_OBJ(selectedPermission?.name)?.color}
                            label={GET_USER_ROLE_OBJ(selectedPermission?.name)?.title}
                        /> . This action will invalidate all existing bintu tokens for this user. <br />
                        Visit <Link target="_blank" href={EDIT_BINTU_TOKEN_DOCS}>our documentation</Link> to learn more about this.
                        Please click on submit to apply changes.
                    </Alert>
                }
                <Stack direction="row" justifyContent={"flex-start"} useFlexGap spacing={1}>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit changes
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
