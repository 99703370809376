import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Grid, Button, Divider, Chip, Avatar, Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Collapse, useTheme, IconButton } from '@mui/material';
import { ArrowRightAlt, ErrorOutline, ExpandLess, ExpandMore, List, ListAltOutlined, OpenInNew, PlayCircleOutline, PlaylistPlayOutlined, SupportAgentOutlined, WarningAmberOutlined } from '@mui/icons-material';

import * as helper from '../../utils/analytics-helper';
import * as link from '../../../utils/helper/link-config';
import * as metrics from '../../utils/api-requests';
import * as ls from '../../../utils/helper/ls-vars';
import * as m from '../../../utils/helper/methods';

import SectionContainer from '../../../components/global/SectionContainer';
import SectionHeader from '../../../components/global/SectionHeader';
import ContentTable from '../../../components/global/ContentTable';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function AdviceTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const [expand, setExpand] = useState(null);
    const [list, setList] = useState([]);

    const handleExpand = (index) => () => {
        setExpand(expand === index ? null : index)
    }

    const handleOpenInNew = (link) => () => {
        // TODO get metrics troubleshoot redirect
        window.open(link, '_target')
    }

    const handleRedirect = (link) => () => {
        navigate(link);
    }

    const handleOpenSupport = (streamname, details) => () => {
        let queries = {
            ...orga && {
                bintu: {
                    id: orga.id,
                    name: orga.name,
                    hash: orga.hash,
                    stream: streamname
                }
            },
            ...(details.country || details.countryName) && {
                location: details.country || details.countryName
            }
            // ...email && { email: email }
        }

        let query = `?${m.TO_QUERY_STRING(queries)}`
        window.open(`${link.NANO_SUPPORT}${query}`, "_blank");
    }

    useEffect(() => {
        setList(props.list);
    }, [props.list])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        "",
                                        "Type",
                                        "Streamname",
                                    ].map((label) => (
                                        <TableCell key={label}>
                                            <Typography variant="button" color="textSecondary">
                                                {label}
                                            </Typography>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ cursor: 'pointer' }}>
                            {
                                list.map((advice, index) => {
                                    let details = advice.streamDetails;
                                    return (
                                        <Fragment key={index}>
                                            <TableRow onClick={handleExpand(index)}>
                                                <TableCell sx={{ border: 0, width: "max-content" }} align="left">
                                                    <IconButton
                                                        onClick={handleExpand}
                                                        size="small"
                                                    >
                                                        {expand === index ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell sx={{ border: 0 }}>
                                                    <Typography sx={{ display: 'inline-block', lineHeight: 1, ml: 1 }} variant="subtitle1">
                                                        {advice.advice.type.label}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ border: 0 }}>
                                                    <Typography sx={{ display: 'inline-block', lineHeight: 1, ml: 1 }} variant="subtitle1">
                                                        {advice.streamname}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                                    <Collapse in={expand === index} timeout="auto" unmountOnExit>
                                                        <SectionContainer
                                                            contrast
                                                            noMargin
                                                            mb
                                                            small
                                                            title={`Advice for stream ${advice.streamname}`}
                                                            underline={`This is an advice.`}
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Divider sx={{ my: 1 }} />
                                                                    {/* <Typography variant="body1">
                                                                        <b>Alert Score</b>: {alert.score}
                                                                    </Typography> */}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TableContainer>
                                                                        <Table size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    {
                                                                                        [
                                                                                            "Code",
                                                                                            "Type",
                                                                                            "Messsage",
                                                                                        ].map((label) => (
                                                                                            <TableCell key={label}>
                                                                                                <Typography sx={{ lineHeight: 1 }} variant="button" color="textSecondary">
                                                                                                    {label}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        ))
                                                                                    }
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <Button
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            onClick={handleOpenInNew(link.ADVICE_CODES)}
                                                                                            endIcon={<OpenInNew fontSize='inherit' />}
                                                                                        >
                                                                                            {advice.advice.code}
                                                                                        </Button>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {advice.advice.type.label}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {advice.advice.message}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ my: 1 }}>
                                                                    {
                                                                        details.map((d, i) => (
                                                                            <Grid item xs={12} key={`connectionsDetails-${i}-advices-${alert.streamname}`} sx={{ my: 1 }}>
                                                                                <SectionContainer
                                                                                    noMargin
                                                                                    small
                                                                                    title={`Ingest Connection Details ${details?.length > 1 ? `(${i + 1})` : ""}`}
                                                                                >
                                                                                    <ContentTable data={[
                                                                                        { label: "City", value: d?.city },
                                                                                        { label: "Country", value: `${d?.country || d?.countryName} ${d?.countryCode ? `(${d?.countryCode})` : ""}` },
                                                                                        { label: "IP", value: d?.ip, copy: true },
                                                                                        { label: "Provider", value: d?.provider, copy: true },
                                                                                        { label: "Server", value: d?.server, copy: true },
                                                                                        { label: "Resolution", value: `${d?.width}x${d?.height}` },
                                                                                    ]} />
                                                                                </SectionContainer>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} mt={1}>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mr: 1, my: 0.5 }}
                                                                    startIcon={<PlayCircleOutline />}
                                                                    onClick={handleRedirect(`${link.PLAYOUT}/${alert.streamname}`)}
                                                                >
                                                                    Playout
                                                                </Button>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mr: 1, my: 0.5 }}
                                                                    startIcon={<PlaylistPlayOutlined />}
                                                                    onClick={handleRedirect(`${link.STREAM}/${advice.streamname}`)}
                                                                >
                                                                    Stream Overview
                                                                </Button>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mr: 1, my: 0.5 }}
                                                                    endIcon={<OpenInNew />}
                                                                    onClick={handleOpenSupport(advice.streamname, details)}
                                                                >
                                                                    Open Support Ticket
                                                                </Button>
                                                            </Grid>
                                                        </SectionContainer>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    )
}
