import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, useTheme } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

import * as metrics from '../utils/api-requests';
import * as helper from '../utils/analytics-helper';
import * as link from '../../utils/helper/link-config';

import SectionHeader from '../../components/global/SectionHeader';
import AlertSection from '../components/alert/AlertSection';
import StreamCountOverview from '../../components/dashboard/StreamCountOverview';
import Loading from '../../components/global/Loading';
import SectionContainer from '../../components/global/SectionContainer';
import AlertTable from '../components/alert/AlertTable';
import AdviceTable from '../components/alert/AdviceTable';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function Alerts(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [advices, setAdvices] = useState([]);
    const [alerts, setAlerts] = useState({
        critical: [],
        moderate: [],
        minor: []
    });

    document.title = "Alerts | nanoStream Cloud Dashboard";

    const openInNew = (link) => () => {
        window.open(link, '_target')
    }

    const getAlerts = () => {

        metrics.getIngestAlertsAndAdvices()
            .then((response) => {
                if (response.success) {
                    let data;
                    if (response.data.organizations) {
                        data = [].concat(...Object.values(response.data.organizations).map(org => org.notifications));
                    } else {
                        data = response.data.notifications;
                    }
                    setAdvices(prevAdvices => {
                        const adviceList = JSON.parse(JSON.stringify(prevAdvices));
                        data.forEach(stream => {
                            stream?.advices.forEach(advice => {
                                adviceList.push({
                                    advice,
                                    streamname: stream.streamname,
                                    streamDetails: stream.streamDetails ? [stream.streamDetails] : stream.connectionsDetails,
                                });
                            });
                        })
                        return adviceList;
                    })
                    setAlerts(prevAlerts => {
                        const alertList = JSON.parse(JSON.stringify(prevAlerts));

                        data.forEach(stream => {
                            stream?.alerts.forEach(alert => {
                                alertList[alert.severity].push({
                                    alert,
                                    streamname: stream.streamname,
                                    streamDetails: stream.streamDetails ? [stream.streamDetails] : stream.connectionsDetails,
                                });
                            });
                        });

                        return alertList;
                    });
                    setLoading(false);

                }
            }).catch((error) => {
                if (!error.success) {
                    setLoading(false);
                }
            })
    }


    useEffect(() => {
        if (loading) getAlerts();
        setInterval(() => {
            getAlerts();
        }, 60 * 1000)
    }, [])

    return (
        <div className={classes.root}>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <SectionHeader
                        title="Alerts"
                        underline="Alerts are providing more transparent insights about the current live stream performances, estimated causes and possible solutions. They offer mutliple capabilities to take immediate action and therefore improve the streaming experience for your audience."
                        interact
                        button={"Documentation"}
                        icon={<OpenInNew />}
                        clicked={openInNew(link.ALERTS_DOCS)}
                    />
                </Grid>
                <Grid item xs={12} my={1}>
                    <StreamCountOverview hideButton />
                </Grid> <Fragment>
                    <Grid item xs={12} lg={6} sx={{ pr: { lg: 2 }, mb: 2 }}>
                        <AlertSection
                            severity="Critical"
                            data={alerts.critical}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} mb={2}>
                        <AlertSection
                            severity="Moderate"
                            data={alerts.moderate}
                            loading={loading}
                        />
                        <AlertSection
                            severity="Minor"
                            data={alerts.minor}
                            loading={loading}
                        />
                        <SectionContainer
                            title={`Advices (${advices?.length})`}
                            underline={advices?.length === 0 ? `There are no advices reported.` : "We've got some advices for you."}
                            extraStyle={{
                                borderLeft: `10px solid ${helper.ALERT_COLOR_SCHEME.advices.color}`
                            }}
                        >
                            {advices?.length > 0
                                && <AdviceTable list={advices} />
                            }
                        </SectionContainer>
                    </Grid>
                </Fragment>
            </Grid>
        </div >

    )
}
